import { useState } from 'react';
import { ForceGraph2D } from 'react-force-graph';

let nodes = [
    {
        "id": "root",
        "name": "Root",
        "val": 10
    },
    // {
    //     "id": "id2",
    //     "name": "name2",
    //     "val": 10
    // },
];

let links = [
    // { "source": "id1", "target": "id2"}
]

const nodeCanvasObject = (node, ctx, globalScale, colorOverrides) => {
    const label = node.name;
    const fontSize = 20/globalScale;
    ctx.font = `${fontSize}px Sans-Serif`;
    const textWidth = ctx.measureText(label).width;
    const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.7); // some padding

    if (colorOverrides[node.id]) {
        ctx.fillStyle = colorOverrides[node.id].fg ?? 'white';
        ctx.strokeStyle = colorOverrides[node.id].bg ?? 'white';
    } else {
        ctx.fillStyle = 'white';
        ctx.strokeStyle = 'white';
    }

    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.roundRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions, 3);
    ctx.stroke();
    ctx.fill();

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    // ctx.fillStyle = node.color;
    ctx.fillStyle = "black";
    ctx.fillText(label, node.x, node.y);

    node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
}

const nodePointerAreaPaint = (node, color, ctx) => {
    ctx.fillStyle = color;
    const bckgDimensions = node.__bckgDimensions;
    bckgDimensions && ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);
}

export function MindMap() {
    const [data, setData] = useState({
        "nodes": nodes,
        "links": links
    });
    const [selectedNodeId, setSelectedNodeId] = useState("root");
    const [linkingNodeId, setLinkingNodeId] = useState(null);

    const colorOverrides = {}
    const selectedColor = "#3498DB";
    const linkingColor = "#FFC300";
    if (selectedNodeId) colorOverrides[selectedNodeId] = { fg: selectedColor };
    if (linkingNodeId) colorOverrides[linkingNodeId] = { bg: linkingColor };
    if (selectedNodeId === linkingNodeId) {
        colorOverrides[selectedNodeId] = { fg: selectedColor, bg: linkingColor };
    }

    const update = () => {
        setData({
            "nodes": nodes,
            "links": links
        });
    }

    const addNode = (name) => {
        const nextValue = nodes.length + 2;
        const id = "id" + nextValue

        nodes = [...nodes, {
            "id": id,
            "name": name,
            "val": 10
        }]

        if (selectedNodeId) {
            links = [...links, {
                "source": selectedNodeId,
                "target": id
            }];
        }

        update();
    }

    const deleteHighlighted = () => {
        nodes = nodes.filter(n => n.id !== linkingNodeId)
        links = links.filter(l => l.source.id !== linkingNodeId && l.target.id !== linkingNodeId)

        update();
    }

    const onNodeClick = (node, event) => {
        if (node == null) {
            setSelectedNodeId(null);
        } else {
            setSelectedNodeId(node.id);
        }
    }

    const onNodeRightClick = (node, event) => {
        if (node == null) {
            setLinkingNodeId(null);
        } else if (linkingNodeId == null) {
            setLinkingNodeId(node.id);
        } else {
            // Get the newly clicked node.
            // Add a new link.

            if (node.id !== linkingNodeId) {
                links = [...links, {
                    "source": linkingNodeId,
                    "target": node.id
                }];

                update();
            }

            // Clear the selected node.
            setLinkingNodeId(null);
        }
    }

    const onKeyUp = (event) => {console.log(event);}
    const pinSelected = (event) => {
        nodes = nodes.map((n) => {
            if (n.id === selectedNodeId) {
                return {
                    ...n,
                    fx: n.x,
                    fy: n.y,
                }
            } else {
                return n;
            }
        })

        update();
    }

    return (<div>
        <Input onEnter={addNode}/>
        { selectedNodeId && <button onClick={pinSelected}>Pin</button>}
        { linkingNodeId && <button onClick={deleteHighlighted}>Delete highlighted?</button>}
        <br />
        <SaveButton />
        <LoadButton onLoaded={update}/>
        <Graph
            data={data}
            onNodeClick={onNodeClick}
            onNodeRightClick = {onNodeRightClick}
            colorOverrides={colorOverrides}
            onKeyUp={onKeyUp}
            />
    </div>)
}

export function SaveButton() {
    const onSave = (event) => {
        // We've got to strip out all of the internal data that the graph adds.
        const nodesToSave = nodes.map(n => { return { id: n.id, name: n.name, val: n.val }});
        const linksToSave = links.map(l => { return { source: l.source.id, target: l.target.id }});

        localStorage.setItem("nodes", JSON.stringify(nodesToSave));
        localStorage.setItem("links", JSON.stringify(linksToSave));
    }
    return (<button onClick={onSave}>Save</button>)
}

export function LoadButton(props) {
    const onLoad = (event) => {
        const loadedNodes = localStorage.getItem("nodes");
        const loadedLinks = localStorage.getItem("links")

        if (loadedNodes && loadedLinks) {
            nodes = JSON.parse(loadedNodes);
            links = JSON.parse(loadedLinks);

            props.onLoaded();
        }
    }
    return (<button onClick={onLoad}>Load</button>)
}

export function Input(props) {
    const [contents, setContents] = useState("")

    const onKeyUp = (event) => {
        if (event.key === "Enter") {
            props.onEnter(contents);
            setContents("");
        }
    }

    return (
        <input type="text"
            value={contents}
            onChange={(event) => {setContents(event.target.value)}}
            onKeyUp={onKeyUp}
            />
    )
}

export function Graph(props) {
    return (<ForceGraph2D
        onKeyUp={props.onKeyUp}
        graphData={props.data}
        onNodeClick={props.onNodeClick}
        onNodeRightClick={props.onNodeRightClick}
        onBackgroundClick={(event) => props.onNodeClick(null, event)}
        onBackgroundRightClick={(event) => props.onNodeRightClick(null, event)}
        nodeAutoColorBy="group"
        // dagMode="radialout"
        linkColor={() => "grey"}
        linkWidth={3}
        nodeCanvasObject={(node, ctx, globalScale) => nodeCanvasObject(node, ctx, globalScale, props.colorOverrides)}
        // nodeCanvasObjectMode={() => "after"}
        nodePointerAreaPaint={nodePointerAreaPaint}
        onNodeDragEnd={node => {
            node.fx = node.x;
            node.fy = node.y;
            node.fz = node.z;
          }}
    />)
}
