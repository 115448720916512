import './App.css';

import { MindMap } from './Graph'

function App() {
  return (
    <div className="App">
      <MindMap />
    </div>
  );
}

export default App;
